body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-color
{
  color: #2b54a4;
}
.m-bg-color
{
  background-color:#2b54a4;
}

.c-btn{  
    color: white !important;
    background-size: 300% 300% !important;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%) !important;
    background-image: linear-gradient(to right, #895cf2 0%, #ffabf4 50%, #895cf2 100%) !important;

    background-image: linear-gradient(to right, #de2626 100%, #0a187d 0%, #042c7c 0%) !important;

    transition: 0.5s !important;
}

/* nav bar */
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link
{
  color: #2b54a4 !important;
  font-weight: bold;
  border-bottom: 1px solid;
}
.nav-link:hover
{
  color: #2b54a4 !important;
  border-bottom: 1px solid;
}
/* nav bar */






.question {
  width: 75%
}

.options {
  position: relative;
  padding-left: 40px
}

#options label {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  cursor: pointer
}

.options input {
  opacity: 0
}

.checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #555;
  border: 1px solid #ddd;
  border-radius: 50%
}

.options input:checked~.checkmark:after {
  display: block
}

.options .checkmark:after {
  content: "";
  width: 10px;
  height: 10px;
  display: block;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 300ms ease-in-out 0s
}

.options input[type="radio"]:checked~.checkmark {
  background: #21bf73;
  transition: 300ms ease-in-out 0s
}

.options input[type="radio"]:checked~.checkmark:after {
  transform: translate(-50%, -50%) scale(1)
}


@media(max-width:576px) {
  .question {
      width: 100%;
      word-spacing: 2px
  }
}
