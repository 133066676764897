.banner {
    position: relative;
    overflow: hidden;
    background: #fff;
    background: url("/public/images/PharmXplain.jpg") no-repeat;
    /* background: url("/public/images/med.jpg") no-repeat; */
    background-size: cover;
    min-height: 400px;
    background-position: left;
  }
  .banner .block {
    padding: 80px 0px 160px;
}
.features1 {
    margin-top: 70px
}

.feature-item {
    flex-basis: 33.33%;
    margin: 0px 10px;
    padding: 40px 30px;
    background-color: #fff;
    border-radius: 15px 15px 15px 15px;
    box-shadow: 0px 0px 30px 0px rgb(0 42 106 / 10%);
}

.about-img img {
    border-radius: 5px;
    box-shadow: 0px 0px 30px 0px rgb(0 42 106 / 10%);
}